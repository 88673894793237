@media (max-width: 480px){
.navbar-default .navbar-nav > li > a.active::after {
    background-color:white !important; 
}
.navbar-default .navbar-nav > li > a::after {
    background-color: white !important;
}
.search-btn, .toggle-btn { 
    margin-bottom: 15px;
}
.header-call {
    float: right;
    padding-left: 0px;
    width: 180px;
}
.logo {
    position: static;
    height:0;
}
footer {
    font-size: 12px !important;
}
.header-call p span{
    font-size: 16px;
    float: right;
}
.header-call p span i{
    font-size: 20px;
}
.header .header-phone a, .header .header-mail a{
    padding-bottom: 26px;
}
.header-social ul{
    padding-top: 0px;
}
.header-social li {
    padding-left: 0px;
}
.header-social li i {
    padding-left: 35px;
}
.header-social li i:first-of-type {
    padding-left: 40px;
}
.header-social ul li a{
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.locales .locale {
    padding-right: 15px;
    padding-left: 15px;
}
.navbar .container{
    padding: 10px 15px;
}
.navbar{
    min-height: 0px;
}
.navbar-collapse {
    margin-top: 45px;
}
.navbar-brand img{
    width: 83px;
    height: 80px;
}
.navbar-default .navbar-nav > li > a {
    font-size: 15px;
}
.navbar-header{
    float: left;
}
.navbar-right{
    float: right;
    margin-top: 4px;
}
.navbar-toggle {
    margin-left: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-color: midnightblue !important;
    background-color: midnightblue !important;
    padding-bottom: 10px;
    padding-top: 10px;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: white !important;
}
.navbar-default .navbar-toggle:hover {
    border-color: slateblue !important;
    background-color: slateblue !important;
}
.nav-button{
    padding: 7px 16px;
}
.navbar-nav > li > a{
    padding-left: 15px;
    padding-right: 6px;
}
.main-nav{
    padding-top: 4px;
}
.navbar-default .navbar-nav > li > a.active::after{
    bottom: -2px;
    height: 2px;
    width: 100%;
}
.navbar-default .navbar-nav > li > a::after{
    bottom: -2px;
    height: 2px;
}
.slider-area .container{
    padding: 0px 25px;
}
.slider-content {
    padding: 15px;
    top: 0;
    position: initial;
}
.search-toggle .mar-l-20 {
    margin-left: 0px;
}
.prop-btm-sheaerch{
    display: block;
    margin: 0 auto;
}
.slider-content h2{
    font-size: 21px;
    margin-bottom: 0px;
}
.slider-content p{
    font-size: 12px;
    margin-bottom: 18px;
}
.search-form{
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
}
.search-form .form-inline .form-group {
    width: 100%; 
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;  
}
.bootstrap-select button.dropdown-toggle { 
    width: 100%;
}
.search-form .search-toggle {
    margin-top: 15px;
    overflow: hidden;

    padding: 0px;
}
.form-inline .form-control, .form-inline .form-control { 
    width: 100%;
}
.slider-area{
    height:440px; 
}
.home-area-1 { 
    padding-top: 65px;
}
#bg-slider .item img {
    height: 440px;
}
.slider .owl-pagination {
    bottom: 165px;
    right: 0;
    width: 30px;
}
.page-head-content .page-title {
    font-size: 25px;
}
.page-title h2 {
    font-size: 20px;
    margin-top: 2px;
}
.page-title h5 {
    font-size: 12px;
}
.page-title p {
    font-size: 12px;
    line-height: 20px;
    margin-top: 10px;
    padding: 0 5%
} 
.nav > li > a {
    padding: 8px 10px;
}
.nav-tabs > li > a {
    font-size: 12px;
}
.tab-content ul li {
    height: 235px;
    margin-bottom: 20px;
    width: 219px;
}
.tab-content ul li a img {
    height: 100%;
    width: 100%;
}
.pl0 {
    padding-left: 0px !important;
}
.layout-switcher { 
    display: none;
}
.main-menu .dropdown-toggle {
    padding: 5px 0px 5px 0px !important;
}
}

@media (min-width: 481px) and (max-width: 767px){
.navbar{
    min-height: 0px;
}
.slider-content {
    top: 15%;
}

.header-call {
    float: right;
    padding-left: 0px;
}

.logo {
    position: static;
}

.search-form{
    padding: 15px;
}
.search-form .form-inline .form-group {
    width: 100%; 
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;  
}
.search-form .search-toggle {
    margin-top: 15px;
    overflow: hidden;
    color: gray;
    border-top: 1px solid #EAEAEA;
    padding: 0px;
}
.search-btn, .toggle-btn {
    margin-bottom: 15px;
}
.prop-btm-sheaerch{
    display: block;
    margin: 0 auto;
}
.search-toggle .mar-l-20 {
    margin-left: 0px;
}
.box-tree .item-tree-icon {
    padding-top: 20%;
}
.header-call p span {
    font-size: 16px;
    margin-right: 4px;
}
.header-social ul{
    padding-top: 0px;
}
.header-social ul li a {
    font-size: 16px;
}
.navbar .container {
    padding: 10px 15px;
}
.navbar-collapse {
    margin-top: 45px;
}
.navbar-brand img {
    width: 83px;
    height: 80px;
}
.navbar-default .navbar-nav > li > a {
    font-size: 15px;
}
.navbar-header{
    float: left;
}
.navbar-right{
    float: right;
    margin-top: 4px;
}
.navbar-toggle {
    margin-left: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-color: midnightblue !important;
    background-color: midnightblue !important;
    padding-bottom: 10px;
    padding-top: 10px;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: white !important;
}
.navbar-default .navbar-toggle:hover {
    border-color: slateblue !important;
    background-color: slateblue !important;
}
.nav-button {
    font-size: 16px;
    padding: 7px 13px;
}
.navbar-nav > li > a {
    padding-left: 15px;
    padding-right: 6px;
}
.main-nav {
    padding-top: 4px;
}
.navbar-default .navbar-nav > li > a.active::after {
    bottom: -2px;
    height: 2px;
    width: 100%;
    background-color: white !important; 
}
.navbar-default .navbar-nav > li > a::after {
    bottom: -2px;
    height: 2px;
    background-color: white !important;
}
.slider-content {
    margin-left: 0px;
    padding: 25px;
    top: 10%;
}
.slider-content h2 {
    font-size: 24px;
    margin-bottom: 15px;
}
.slider-content p {
    font-size: 12px;
    margin-bottom: 50px;
}
.search-form {
    padding: 20px 30px;
}
.slider-area{
    height:440px;        
}
#bg-slider .item img {
    height: 440px;
}
.slider .owl-pagination {
    bottom: 165px;
    right: 0;
    width: 30px;
}
.page-title{
    padding-top: 50px;
}
.page-title h2 {
    font-size: 20px;
    margin-top: 2px;
}
.page-title h5 {
    font-size: 12px;
}
.page-title p {
    line-height: 20px;
    margin-top: 10px;
    font-size: 12px;
}
.nav > li > a {
    padding: 8px 10px;
}
.nav-tabs > li > a {
    font-size: 12px;
}
.pr0 {
    padding-right: 15px !important;
}
}

.yamm .yamm-content {
    padding: 10px 20px;
}
.yamm .nav {
    width: 100%;
    padding-left: 10px;
}
.navbar .yamm-content h5 {
    font-size: 14px;
}
ul {
    padding-left: 20px;
}
.top-menu {
    margin-bottom: 5px !important;
}
.header-phone, .header-mail, .header-social {
    text-align: right;
}
.full-picture {
    width: 100%;
}
.ymm-sw .dropdown-menu {
    position: static;
    float: none;
    padding: 0px;
    margin-bottom: 10px;
    border: 1px solid #CECECE;
    border-top: 5px solid midnightblue;
    box-shadow: none;
}
.main-menu .dropdown-toggle {
    padding: 5px 0px 5px 0px !important;
}
.container.pictures.comparison .row:not(:last-child) {
    border-bottom: 2px solid midnightblue;
    margin-bottom: 10px;
}
.locales {
    padding-top: 0px;
}